import React from 'react'

function Loader() {
  return (
    <section id='loader' className='loader'>
        <div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </section>
  )
}

export default Loader