const ServicesItems = [
    {
        title: 'Team',
        path: '/#team'
    },
    {
        title: 'Career',
        path: '/career'
    },
    // {
    //     title: 'Contact Us',
    //     path: '/contactus'
    // }
   
]

export default ServicesItems