import React from 'react'
import CookieConsent from "react-cookie-consent";

function Cookie() {
  return (
<CookieConsent
  location="bottom"
  buttonText="I Accept"
  cookieName="GSS_Cookies"
  style={{ background: "var(--navBackColorOld)" }}
  buttonStyle={{ backgroundColor: "white", fontSize: "14px",color:"black",fontWeight:"600" }}
  expires={150}
>
We collect cookies to analyze our website traffic and performance, we never collect any personal data.
</CookieConsent>
  )
}

export default Cookie