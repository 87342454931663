import React from "react";
import pm1 from "../images/pm1.png";
import pm5 from "../images/pm5.png";
import pm3 from "../images/pm3.gif";
import pm4 from "../images/pm4.gif";
import pm6 from "../images/pm6.gif";

function ProjectManagement() {
  return (
    <section className="proManage">
      <section className="pmFirstPage">
        <div className="pmHead">
          <div class="textDiv valuesHead">
            <div></div>
            <div data-aos="fade-left">PROJECT MANAGEMENT</div>
          </div>
        </div>
        <div className="cardCont">
          <div className="parentCard">
            <div className="card card1" data-aos="fade-down">
              24/7 SUPPORT
              <img src={pm1} alt="" />
            </div>
            <div className="card card2" data-aos="fade-up">
              <div>INCREASED</div>
              <div>EFFICIENCY AND PRODUCTIVITY</div>

              <div className="arrowCont">
                <a href="#pms">
                  {" "}
                  <i
                    class="fa-solid fa-arrow-right"
                    style={{ color: "white" }}
                  ></i>
                </a>
              </div>
            </div>
          </div>
          <div className="parentCard">
            <div className="card card3" data-aos="fade-down">
              Expertise and Experience
              <a href="#pms">
                <i class="fa-solid fa-plus" style={{ background: "white" }}></i>
              </a>
            </div>
            <div className="card card4" data-aos="fade-up">
              Scalability and Flexibility
              <a href="#pms">
                <i
                  class="fa-solid fa-plus"
                  style={{ background: "red", color: "white" }}
                ></i>
              </a>
            </div>
          </div>
          <div className="parentCard">
            <div className="card card5" data-aos="fade-down">
              Focus on Core Competencies
              <a href="#pms">
                <i
                  class="fa-solid fa-plus"
                  style={{ background: "#aa02ff", color: "white" }}
                ></i>
              </a>
            </div>
            <div className="card card6" data-aos="fade-up">
              Learning & Development Community
              <a href="#pms">
                <i class="fa-solid fa-plus"></i>
              </a>
            </div>
          </div>
          <div className="parentCard lastCard">
            <div className="card card7" data-aos="fade-down">
              Risk Mitigation
              <a href="#pms">
                <i class="fa-solid fa-plus" style={{ color: "black" }}></i>
              </a>
            </div>
            <div className="card card8" data-aos="fade-up">
              <div className="card8Text"> PROJECT MANAGEMENT</div>

              <img src={pm5} alt="" />
              <a href="#pms">
                <i
                  class="fa-solid fa-plus"
                  style={{ background: "orange", color: "white" }}
                ></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="pmSecondPage" id="pms">
        <div className="pmCont">
          <div className="proHead">PROJECT MANAGEMENT SERVICES</div>
          <div className="flex pmCardCont">
            <div className="pmCard" data-aos="fade-right">
              <div className="headImage">
                <img src={pm3} alt="" />
              </div>
              <div className="head">
                COST <br /> SAVINGS
              </div>
              <div className="text">
                We have skilled resources on a needed basis. This can reduce
                overhead costs associated with salaries, benefits, office space,
                and equipment.
              </div>
            </div>
            <div className="pmCard" data-aos="fade-up">
              <div className="headImage">
                <img src={pm4} alt="" />
              </div>
              <div className="head">
                FOCUS ON RESULTS <br /> AND DELIVERIES
              </div>

              <div className="text">
                Project managers are accountable for achieving project goals,
                meeting deadlines, and delivering high-quality outcomes. They
                monitor progress, track key performance indicators, and make
                data-driven decisions to drive project success.
              </div>
            </div>
            <div className="pmCard" data-aos="fade-left">
              <div className="headImage">
                <img src={pm6} alt="" />
              </div>
              <div className="head">
                ENHANCED COMMUNICATION AND COLLABORATION
              </div>
              <div className="text">
                Establish effective communication channels, provide regular
                status updates, and facilitate seamless coordination between
                team members, ensuring everyone is aligned and informed.
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default ProjectManagement;
