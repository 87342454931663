import React from "react";
import logo from "../images/logo.png";
function Footer() {
  return (
    <section className="footerSection">
      <div className="footer">
        <div className="leftFoot">
          <a href="/" className="footerLogoLink">

         
          <div className="logoCont">
            <img src={logo} alt="" />
            <div>GLOBAL SURVEY SOLUTIONS</div>
          </div>
            </a>
          <div className="leftLinksDivCont">
            <div className="leftLinksDiv">
              <br />
              <div className="linkHead">Services</div>
              <br />
              <a href="/surveyprogramming">Survey Programming</a> <br />
              <a href="/dataProcessing">Data Processing</a> <br />
              <a href="/projectManagement">Project Management</a> <br />
              <a href="/panel">Panel Services</a>
            </div>

            <div className="leftLinksDiv">
              <br />
              <div className="linkHead">Company</div>
              <br />
              <a href="/#aboutUs">About Us</a> <br />
              <a href="/#team">Team</a> <br />
              <a href="/career">Career</a> <br />
              <a href="">Newsroom</a>
            </div>

            <div className="leftLinksDiv">
              <br />
              <div className="linkHead">Contact</div>
              <br />
              <a href="/contactus">Contact Sales</a> <br />
              <a href="/contactus">Get Help</a> <br />
              <a href="/contactus">Contact Us</a> <br />
              <a href="">Twitter</a>
            </div>
          </div>
        </div>
        <div className="rightFoot">
          <div style={{ fontWeight: "bolder" }}>TALK TO US</div>
          <div>+91 9899061991</div>
          <br />
          <i>
            <a
              style={{ color: "white" }}
              style={{ textDecoration: "none", color: "white" }}
              href="mailto:contactus@globalsurveysolutions.com"
            >
              contactus@globalsurveysolutions.com
            </a>
          </i>

          <br />
          <br />
          <div>
            <i>
              3rd Floor, GM IT Park Sector 142, Noida Pincode: 201304, India
            </i>
          </div>

          <div className="socialLinksDiv">
            <a
              href="https://www.facebook.com/GlobalSurveySolutions/"
              target="blank"
            >
              <i class="fab fa-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/globalsurveysolutions_/?igshid=OGQ2MjdiOTE%3D"
              target="blank"
            >
              <i class="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/global-survey-solutions"
              target="blank"
            >
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="copyrightTag">Copyright © 2023 - Global Survey Solutions</div>
    </section>
  );
}

export default Footer;
