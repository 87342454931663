import React, { useState } from "react";
import messageImg from "../images/message.png";
import telephone from "../images/telephone.png";
import location from "../images/location.png";
import ReCAPTCHA from "react-google-recaptcha";




function ContactUs() {
  const [email,setEmail]  = useState("");
  const [firstName,setFirstName]  = useState("");
  const [lastName,setLastName]  = useState("");
  const [mob,setMob]  = useState("");
  const [message,setMessage]  = useState("");
  const [capSub,setCapSub] = useState(false);


setTimeout(()=>{
  document.querySelector("#g-recaptcha-response").setAttribute("required","required");
},1000)
 

function checkInput(){
   if(firstName && lastName  && message && capSub){
    document.querySelector(".formSubmitBtn").setAttribute("class","formSubmitBtn activeBtn");
   }else{
    document.querySelector(".formSubmitBtn").setAttribute("class","formSubmitBtn");

   }
}

function checkCaptcha(){
  if(firstName && lastName && message){
    document.querySelector(".formSubmitBtn").setAttribute("class","formSubmitBtn activeBtn");
   }else{
    document.querySelector(".formSubmitBtn").setAttribute("class","formSubmitBtn");

   }
}

  
  function onFormSubmit(event){
    event.preventDefault();
   

    const config = {
      SecureToken:"6471bdf6-919d-4560-a47c-f958eb201999",
      To : "contactus@globalsurveysolutions.com",
      From : "contactus@globalsurveysolutions.com",
      Subject : "Contacted through website",
      Body : `
        Name :- ${firstName} ${lastName} ,
        Email :- ${email} ,
        Mob :- ${mob}, 
        Message:- ${message} 
      `
    }


    if(window.Email){
      window.Email.send(config).then(
        () => {
          let closeContactBtn = document.querySelector(".contactClose");
          if(closeContactBtn!=null) closeContactBtn.click();
          document.querySelector(".modal").style.transform = "scale(1)";
        }
       );
    }
  }
  return (
    <section className="contactUs" id="contactUs">
      <div className="contactCont">



        <div className="contactDetails">
          <div>
            <div className="imgDiv">
                <img src={location} alt="" />
          
              <div>Address</div>
            </div>
            <div className="details">
              3rd Floor, GM IT Park Sector 142 <br /> Noida, Uttar Pradesh Pin - 201304
              India
            </div>
          </div>

          <div>
            <div className="imgDiv">
              <img src={telephone} alt="" />
              <div>Let's Talk</div>
            </div>
            <div className="details">+91 9899061991</div>
          </div>
          <div>
            <div className="imgDiv">
              <img src={messageImg} alt="" />
              <div>General Support</div>
            </div>
            <div className="details">contactus@globalsurveysolutions.com</div>
          </div>

          <div>
            <div className="imgDiv">
              <img src={messageImg} alt="" />
            <div>For Sales</div>
            </div>
            <div className="details">rfq@globalsurveysolutions.com</div>
          </div>
        </div>



        <div className="contactForm">
        <div className="formHead">Send Us A Message</div>
        <form onSubmit={onFormSubmit} className="contactUsForm">
       
        <div className="label">TELL US YOUR NAME*</div>
        <input type="text" placeholder="First Name" onChange={(e)=>setFirstName(e.target.value)}  onKeyUp={checkInput} name="firstName" required /><input type="text" onKeyUp={checkInput} placeholder="Last Name" onChange={(e)=>setLastName(e.target.value)}  name="LastName" required />
       
       <div style={{display:"flex"}}>
        <div style={{width:"40%"}}>
        <div className="label">ENTER YOUR EMAIL*</div>
        <input style={{width:"100%"}}type="email" name="email" onChange={(e)=>setEmail(e.target.value)} placeholder="Eg. example@email.com" required onKeyUp={checkInput} />
        </div>
        <div style={{width:"40%"}}>
        <div className="label">ENTER YOUR PHONE NUMBER</div>
        <input onKeyUp={checkInput} type="number" style={{width:"100%", marginLeft:"20px"}} onChange={(e)=>setMob(e.target.value)} name="phone" placeholder="Eg. + 91 00000000" />
        </div>
        </div>
        <div className="label">Message*</div>
        <textarea onKeyUp={checkInput} placeholder="Write us a message" onChange={(e)=>setMessage(e.target.value)} name="message" required></textarea>
        <br />
        <div className="captchaCont">
          <ReCAPTCHA required
                 sitekey={"6LeX_QQkAAAAAB0QIYpGkTCl_AeLwQfShypwTL93"}
                 onChange={()=>{
                  setCapSub(true);
                  checkInput();
                  checkCaptcha();
                }}
                 />
                 
                 </div>
        
        <div className="buttonCont"> <button class="formSubmitBtn" type="submit">SUBMIT</button></div>
      
        </form>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
