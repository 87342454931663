import React from "react";
import spGif from "../images/spGif.gif";
import dpGif from "../images/dpGif.gif";
import pmGif from "../images/pmGif.gif";
import panelGif from "../images/panelGif.gif";
import ServicesCard from "./ServicesCard";

function Services() {
  return (
    <section className="services" id="services">
      <div className="gifContParent">

        <ServicesCard
          gif={spGif}
          title1="Survey"
          title2="Programming"
          route="surveyProgramming"
          animation="fade-right"
        />

        <ServicesCard
          gif={dpGif}
          title1="Data"
          title2="Processing"
          route="dataProcessing"
          animation="fade-left"
        />

        <ServicesCard
          gif={panelGif}
          title1="Panel"
          title2="Services"
          route="panel"
          animation="fade-right"
        />
        
        <ServicesCard
          gif={pmGif}
          title1="Project"
          title2="Management"
          route="projectManagement"
          animation="fade-left"
        />
      </div>
    </section>
  );
}

export default Services;
