import React from "react";
import OrangeBrush from "../images/orangeBrush.png";
import Comma from "../images/comma.png"

function Testimonials() {
  return (
    <section id="testimonial" className="testimonial">
      <div className="testCont">

        <div className="leftTestCont">
            <img src={Comma} alt="" data-aos="zoom-in"/>
          <div data-aos="fade-right">CUSTOMER <br /> <span>STORIES</span></div>
          <div data-aos="fade-left">
            We love to hear more, Here are few of many client stories that
            helped them to complete there research projects.
          </div>
        </div>

        <div className="textDivCont">
          <div className="textDiv" data-aos="fade-left">
            <img src={OrangeBrush} alt="" />
            <div className="text">You guys are fully engaged to fully understand our business process
needs to subsequently scope out, develop and deliver on our exact
requirements. Overall we are impressed with GSS’s professionalism and
their degree of engagement to ensure successful project delivery. Hence,
we would recommend Global Survey Solutions to any business.
VP, US Research Agency.</div>
          </div>
          <div  className="textDiv" data-aos="fade-right">
            <img src={OrangeBrush} alt="" />
            <div className="text">Despite all their change requests, the client is very happy with your work.
That is why I also wanted to pass on the following comment from them
to you and your team: :-)
"We greatly appreciate that you have been very patient with us. Seeing
the survey on screen, especially the conjoint tasks, and making all these
adjustments has been an important part of the research process for us
and we are really grateful that you have facilitated this. Thanks again!".
</div>
          </div>
          <div  className="textDiv" data-aos="fade-left">
            <img src={OrangeBrush} alt="" />
            <div className="text">Everything was accurately checked at the set-up stage, and even the
client errors were spotted before the project was launched. The
beginning of the month is the most stressful time for me, but not this
time. Especially, I valued the approach that programmer took in this
project, he suggested or offered various methods to tackle the
client's technical requests.
</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
