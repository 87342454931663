import React from "react";
import pmGif from "../images/dpGif.gif";
import pc from "../images/pc.png";
import aboutUsGif1 from "../images/aboutUs1.gif";
import values1 from "../images/values1.png";
import values2 from "../images/values2.png";

function AboutUs() {
  return (
    <section className="aboutUs" id="aboutUs">
      <div className="flex aboutUsCont">
        <div className="aboutUsLeft" data-aos="fade-right">
          <div className="aboutUsImgDiv" style={{ position: "relative" }}>
            <img src={aboutUsGif1}/>
            <div className="textInGif">"Building quality with economy"</div>
          </div>
        </div>
        <div className="aboutUsRight">
          <div className="aboutUsHeadingParent">
            <div className="aboutUsHeading">
              <div className="textDiv flex">
                <div></div>
                <div data-aos="fade-left">WHO WE ARE</div>
              </div>
            </div>
          </div>
          <div className="content" data-aos="fade-left">
            With over a decade of excellence, we are the trusted leader in
            market research solutions. Our commitment to delivering exceptional
            insights and unwavering dedication to client satisfaction sets us
            apart. Leveraging our extensive experience, advanced methodologies,
            and a team of industry experts, we consistently deliver actionable
            intelligence that drives success. As a seasoned service provider, we
            continue to redefine the standards of excellence in the market
            research industry, helping our clients thrive and achieve their
            business goals for over 10 years and counting.
          </div>
          <div className="flex content pcCont" data-aos="fade-left">
            <img src={pc} />
            <div className="smallText" data-aos="fade-left">
              Achieving The Main Goal <br /> of a Trustworthy Customer
            </div>
          </div>
          <div className="content" data-aos="fade-left" data-aos-offset="10">
            To envision, service and deliver the most magnificent landmarks and
            edifices to contribute tangibly to the research industry.
          </div>
        </div>
      </div>

      <div className="flex aboutUsCont valuesCont">
        <div className="aboutUsRight">
          <div className="aboutUsHeadingParent">
            <div className="aboutUsHeading">
              <div className="textDiv flex valuesHead">
                <div></div>
                <div data-aos="fade-left">OUR VALUES AND BEHAVIORS</div>
              </div>
            </div>
          </div>
          <div className="content" data-aos="fade-left">
Our commitment to client satisfaction is unwavering. We firmly believe in respecting our clients, paying attention to their requests, and comprehending their needs. In terms of cost, quality, and delivery time, we work hard to go above and beyond customer expectations. In terms of value received for money spent, our goods and services will be among the "best in class". We promise to provide excellence, work hard to improve constantly, and embrace change with vigour. The calibre of everything we accomplish is up to each and every one of us.
          </div>
          <div className="flex content" data-aos="fade-left">
            <img className="values1Img" src={values1} />
          </div>
        </div>

        <div className="aboutUsLeft" data-aos="fade-right">
          <div>
            <img src={values2} className="values2Img" data-aos="zoom-in" />
            <div className="valuesBlueDiv">
              GSS' leadership is founded on talented employees effectively
              applying advanced technology, innovative thinking and sound
              business management. We add more value at lower cost with faster
              response. We each lead through our competence, creativity and
              teamwork.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
