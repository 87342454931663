import React,{useState} from 'react'
import {useEffect} from "react";
import AOS from "aos";

function Home() {

  function showContact(){
    document.querySelector(".contactPopUp").style.zIndex = "200";
    document.querySelector(".contactPopUp").style.display = "flex";
    setTimeout(()=> document.querySelector(".contactPopUp").style.transform = "scale(0.8)",20)
    document.querySelector(".captchaCont").style.pointerEvents = "revert"

}

  function removeActive() {
    let allNavOptions = document.querySelectorAll(".nav-links");
    for (let i = 0; i < allNavOptions.length; i++) {
      if (allNavOptions[i].classList.contains("active")) {
        allNavOptions[i].classList.remove("active");
      }
    }
  }

  function addActive(index) {
    removeActive();
    let allNavOptions = document.querySelectorAll(".nav-links");
    for (let i = 0; i < allNavOptions.length; i++) {
      if (i == index) allNavOptions[i].classList.add("active");
    }
  }

  function changeNav() {
    removeActive();
    const scrollValue = document.documentElement.scrollTop;
    if (scrollValue < document.querySelector(".services").offsetTop) {
      addActive(0);
    }
    if (scrollValue >= document.querySelector(".services").offsetTop - 50) {
      addActive(1);
    }
    if (scrollValue >= document.querySelector(".aboutUs").offsetTop - 50) {
      addActive(2);
    }
    if (scrollValue >= document.querySelector(".rfq").offsetTop - 70) {
      addActive(3);
    }
  }
  window.addEventListener("scroll", changeNav);
    
    let wordArr = ["DATA QUALITY","TRUST","INNOVATION"];
    const [myWord,setMyWord] = useState(wordArr[0]);
    const [myWordWidth,setMyWordWidth]=useState("100%");
  
  
  let wordIdx = 0;
  function changeWord(){
      if(wordIdx==wordArr.length-1) wordIdx = 0;
      else wordIdx++;

      setMyWordWidth("0%");
      setTimeout(changeWidth,1000)
  }
  
  
  function changeWidth(){
    
      setMyWord(wordArr[wordIdx])
      setMyWordWidth("100%");
  
  }
  useEffect(() => {
    const wordInterval = setInterval(changeWord,2000);
  
    return () => clearInterval(wordInterval);


  
  }, [])

  useEffect(()=>{
   
    AOS.init(
      {
        offset: 100,
        duration: 800,
        easing: 'ease-in-quad',
        delay: 250,
      }
    );

  },[])
  return (
    <section className='home' id='home'>
        <div className="sloganParent">
        <div className='slogan' data-aos="fade-right">MAKE IT <span>HAPPEN</span></div> <div className='orangeBack'></div></div>
        <div className='flex focusCont' ><div data-aos="fade-right">OUR FOCUS</div> <div className='myWordContPar'><div className='myWordCont' data-aos="fade-left" style={{width:myWordWidth}}>{myWord}</div></div></div>
        <div className='mission' data-aos="fade-in">Our mission is to deliver valuable insights and strategic intelligence to empower businesses to make informed decisions and drive growth.</div>
        <div className='flex btnDiv'>
        <a className='exploreBtn' href="#services" data-aos="fade-up">EXPLORE</a>
        <a className='requestBtn' onClick={showContact} data-aos="fade-up">REQUEST A QUOTE</a>
        </div>

        <div className='quote' data-aos="fade-in" data-aos-offset="10">LET'S TOGETHER MAKE YOUR RESEARCH "HAPPEN"</div>
    </section> 
  )
}

export default Home