import React from "react";
import learnMore from "../images/learnMore.png";

function ServicesCard(props) {
  return (
    <div className="gifCont" data-aos={props.animation}>
      <img className="gif" src={props.gif} />
      <div className="orangeLine"></div>
      <div className="serviceName">
        {props.title1} <br /> {props.title2}
      </div>
      <a href={props.route}>
        <img src={learnMore} className="learnMore" />
      </a>
    </div>
  );
}

export default ServicesCard;
