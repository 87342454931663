import React from 'react'

function Modal() {
  function handleClickBtn(){
    document.querySelector('.modal').style.transform = "Scale(0)";
  }
  return (
    <div className='modal'>
        <div className='card'>
          <div className="tick"><i class="fa fa-check" aria-hidden="true"></i></div>
        <div className='cardText'>
          <div>Thank you for contacting us</div>
           someone in team will contact you shortly!!</div>
        <div className='cardBtn' onClick={()=>handleClickBtn()}>OK</div>
        </div>
        </div>
  )
}

export default Modal