import React, { useState } from "react";
import ServicesItems from "./ServicesItems";
import AboutUsItems from "./AboutUsItems";
import Logo from "../images/logo.png";
import DropDown from "./DropDown";

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [aboutDrop, setAboutDrop] = useState(false);
  const changeClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const [navBackColor, setNavBackColor] = useState("");

  let defaultLinkColor = ""
  if(window.location.href.split("/")[3]=="") defaultLinkColor = "white";
  const [navLinksColor, setNavLinksColor] = useState(defaultLinkColor);

  function changeNavColor(){
    const scrollValue = document.documentElement.scrollTop;

      if (scrollValue > 50){
        setNavBackColor("var(--navBackColor)");
        setNavLinksColor("black");
      } 
    else {
      setNavBackColor("");
      setNavLinksColor("white");

    }

  
    
  }


    if(window.location.href.split("/")[3]==""){
      window.addEventListener("scroll",changeNavColor);
    }

  
 
  return (
    <>
      <section className="navSec">
        <nav className="navbar" style={{ background: navBackColor }}>
          <a href="/" className="companyTag">
            <img src={Logo} /> <span  style={{color:navLinksColor}}>GLOBAL</span> <span> &nbsp;SURVEY SOLUTIONS</span>{" "}
          </a>

          <div className="menu-icon" onClick={changeClick}>
            <span  style={{color:navLinksColor}} className={click ? "fas fa-times" : "fas fa-bars"}></span>
          </div>
          <ul className={click ? "nav-side-menu start" : "nav-side-menu"}>
          

            <li
              className="nav-items"
              style={
                click ? { opacity: 1, transition: "all 2s ease 0.1s" } : {}
              }
            >
              <a
                href="/"
                className="nav-links active"
                onClick={closeMobileMenu}
                style={{color:navLinksColor}}
              >
                Home
              </a>
            </li>

            <li
              className="nav-items"
              style={
                click ? { opacity: 1, transition: "all 2s ease 0.3s" } : {}
              }
              onMouseEnter={() => {
                setDropdown(true);
              }}
              onMouseLeave={() => setDropdown(false)}
            >
              <a href="#services" className="nav-links" style={{color:navLinksColor}}>
                Expertise <i class="fa-solid fa-caret-down"></i>
                {dropdown && <DropDown items={ServicesItems} />}
              </a>
            </li>
            <li
              className="nav-items"
              style={
                click ? { opacity: 1, transition: "all 2s ease 0.5s" } : {}
              }
              onMouseEnter={() => {
                setAboutDrop(true);
              }}
              onMouseLeave={() => setAboutDrop(false)}
            >
              <a href="#aboutUs" className="nav-links" style={{color:navLinksColor}}>
                {" "}
                About Us <i class="fa-solid fa-caret-down"></i>
                {aboutDrop && <DropDown items={AboutUsItems} />}
              </a>
            </li>

            <li
              className="nav-items"
              style={
                click ? { opacity: 1, transition: "all 2s ease 0.5s" } : {}
              }
            >
              <a
                href="/contactus"
                className="nav-links"
                onClick={closeMobileMenu}
                style={{color:navLinksColor}}
              >
                Contact
              </a>
            </li>

            <li className="nav-items">
              <span className="navImgCont navLogo">
                <a href="/"></a>{" "}
              </span>
            </li>
          </ul>
        </nav>
      </section>
    </>
  );
}

export default Navbar;
