import './css/main.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Services from './components/Services';
import Team from './components/Team';
import AboutUs from './components/AboutUs'
import RFQ from './components/RFQ'
import Footer from './components/Footer'
import SurveyProgramming from './components/SurveyProgramming';
import DataProcessing from './components/DataProcessing';
import Panel from './components/Panel';
import ContactUs from './components/ContactUs';
import NotFound from './components/NotFound';
import Wcu from './components/Wcu';
import Loader from './components/Loader';
import Cookie from './components/Cookie';
import PopUp from './components/PopUp'
import ProjectManagement from './components/ProjectManagement'
import { useEffect } from 'react';
import { useState } from 'react';
import AOS from 'aos';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import ContactPopUp from './components/ContactPopUp';
import Career from './components/Career';
import Testimonials from './components/Testimonials';
import Modal from './components/Modal'



function App() {
 
  function disableCtrlAndRightClick(){
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault();
   });
  
   document.addEventListener('keydown', function(event) {
      if (event.ctrlKey) {
          event.preventDefault();
      }
  });
  }
 
  disableCtrlAndRightClick();
  // to scroll to team section when click on team in navbar
  setTimeout(() => {
    // if(window.location.href.split("/")[3]=="#aboutUs"){
    //   const aboutUsSection = document.getElementById("aboutUs");
    //     aboutUsSection.scrollIntoView();
    //   }
    if(window.location.href.split("/")[3]=="#team"){
    const teamSection = document.querySelector(".team");
    teamSection.scrollIntoView();
    }
   
  }, 1000);


// to change the active link in nav bar
  setTimeout(()=>{
    if(window.location.href.split("/")[3]=="contactus"){
      let allNavItems = document.querySelectorAll(".nav-links");
      allNavItems[0].classList.remove("active");
      allNavItems[3].classList.add("active");
      document.querySelector(".captchaCont").style.pointerEvents = "revert"
       }
       if(window.location.href.split("/")[3]=="surveyprogramming" || window.location.href.split("/")[3]=="dataprocessing" || window.location.href.split("/")[3]=="panel" || window.location.href.split("/")[3]=="projectManagement" ){
         let allNavItems = document.querySelectorAll(".nav-links");
         allNavItems[0].classList.remove("active");
         allNavItems[1].classList.add("active");
          }
 
          if(window.location.href.split("/")[3]=="career"){
           let allNavItems = document.querySelectorAll(".nav-links");
           allNavItems[0].classList.remove("active");
           allNavItems[2].classList.add("active");
            }
  },50)
  
const [visited, setVisited] = useState(true);
function hideLoader(){
  let loader = document.querySelector(".loader");
  if(loader!=null) document.querySelector("body").style.position = "fixed";
  setTimeout(()=>{
    if(loader!=null){
      loader.style.background = "transparent";
      loader.style.transform = "scale(0)";
    }
  document.querySelector("body").style.position = "revert";
  },3000)
}


useEffect(()=>{
  AOS.init({
    "delay":500,
    "duration":1000
})





if(sessionStorage.getItem('visited')){
  setVisited(false);
}else{
  hideLoader();
}
sessionStorage.setItem('visited',true);

let allSections = document.querySelectorAll("section");
for(let i=0;i<allSections.length;i++){
  allSections[i].addEventListener("click",()=>{
    if(allSections[i].getAttribute("class")=="navSec"){
     
    }else{
      let closeIcon = document.querySelector(".menu-icon .fa-times");
      if(closeIcon != null) closeIcon.click();
    }

  })
}


},[])
setTimeout(()=>{
  let navbar = document.querySelector(".navbar");
  if(window.location.href.split("/")[3]!=="" && navbar!=null){
    navbar.style.backgroundColor = "var(--navBackColor)";
   }
},50)

  return (
    <BrowserRouter>
    <Routes>
    <Route path="/" element={ 
      <>
      <Cookie/>
      <PopUp/>
      <Modal/>
     {visited?<Loader/>:""}
      <Navbar/>
      <Home/>
      <Services/>
      <Wcu/>
      <AboutUs/>
      <Testimonials/>
      <Team/>
      <RFQ/>
      <Footer/>
    <ContactPopUp/>
      </>
    }/>

<Route path="/surveyProgramming" element={ 
      <>
         <Navbar/>
      <SurveyProgramming/>
      <ContactPopUp/>

      <Footer/>
      <Modal/>
      </>
    }/>

<Route path="/dataProcessing" element={ 
      <>
         <Navbar/>
      <DataProcessing/>
      <ContactPopUp/>

      <Footer/>
      <Modal/>
      </>
    }/>

<Route path="/panel" element={ 
      <>
   <Navbar/>
      <Panel/>
      <Footer/>
      <Modal/>
      </>
    }/>


<Route path="/contactus" element={ 
      <>
   <Navbar/>
     
      <ContactUs/>
      <Footer/>
      <Modal/>
      </>
    }/>


<Route path="/projectManagement" element={ 
      <>
   <Navbar/>
      <ProjectManagement/>
      <Footer/>
      <Modal/>
      </>
    }/>

<Route path="/career" element={ 
      <>
   <Navbar/>
      <Career/>
      <Footer/>
      <Modal/>
      </>
    }/>
<Route path="*" element={
<NotFound/>
}/>


    </Routes>

    </BrowserRouter>
  
  );
}

export default App;
