import React from "react";
import dpData from "../images/dataGif.gif";
import dpGif from "../images/dpGif.gif";
import pc from "../images/pc.png";
import data1 from "../images/data1.gif";
import data2 from "../images/data2.png";
import data3 from "../images/data3.png";

function DataProcessing() {
  function showContact(){
    document.querySelector(".contactPopUp").style.zIndex = "200";
    document.querySelector(".contactPopUp").style.display = "flex";
    setTimeout(()=> document.querySelector(".contactPopUp").style.transform = "scale(0.8)",20)
    document.querySelector(".captchaCont").style.pointerEvents = "revert"

   
}
  return (
    <section id="dataProcessing" className="dataProcessing">
      <div className="dpCont flex dpCont1">
        <div className="dpLeft">
          <div className="flex dpSubHead">
            <div className="orangeCircle"></div>
            <img src={pc} alt="" />
            <div className="">Data Visualization</div>
          </div>
          <div data-aos="fade-up">
            We provide Visualizations such as charts, graphs, heatmaps, or
            interactive dashboards can help present complex survey data in a
            visually appealing and easy-to- understand format, enabling
            stakeholders to gain actionable insights at a glance.
          </div>
          <div className="flex dpSubHead">
            <div className="orangeCircle"></div>
            <img src={pc} alt="" />
            <div className="">Transformation and Analysis</div>
          </div>
          <div data-aos="fade-up">
            We convert or reshape the data to make it suitable for analysis. This
            may include standardizing variables, aggregating data at different
            levels (e.g., daily, monthly), creating new derived variables, or
            applying mathematical functions to the data. We work with various
            statistical and analytical techniques are applied to extract
            insights and patterns from the data. This may involve descriptive
            statistics, hypothesis testing, regression analysis, clustering, or
            other advanced analytical techniques depending on the research
            objectives and nature of the data.
          </div>
        </div>
        <div className="dpRight">
          <div class="textDiv flex">
            <div></div>
            <div data-aos="fade-left">DATA PROCESSING</div>
          </div>
          <img src={dpData} alt="" data-aos="fade-left" />
        </div>
      </div>

      <div className="dpCont flex dpCont2">
        <div className="dpRight">
          <img src={dpGif} data-aos="fade-right" alt="" />
        </div>

        <div className="dpLeft">
          <div class="textDiv flex dataAnalysis">
            <div></div>
            <div data-aos="fade-left">DATA ANALYSIS</div>
          </div>
          <div className="flex dpSubHead" style={{ marginLeft: "0px" }}>
            <div className="orangeCircle"></div>
            <img src={pc} alt="" />
            <div data-aos="fade-left"> Tools and other Services</div>
          </div>
          <div className="subHead">Tabulations</div>
          <div data-aos="fade-up">
            Next to the data cleaning and validations, we can provide tables for
            your further analysis purpose. Preparing tables quickly and
            accurately is what our team is capable of and We match exactly what
            your spec says, with all the information like chi square test, sig
            testing and mean. Some key features include..
            <div className="flex toolsList">
              <div>
                <ul>
                  <li>Creating banner tables using ASCII, Excel, SPSS etc.</li>
                  <li>Output formats includes Word, Excel, PDF, CSV etc.</li>
                  <li>Banners Nested or with secondary pivot question.</li>
                  <li>Weighting/Complex Weighting</li>
                  <li>Charts along with tables</li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>Quantum</li>
                  <li>SPSS</li>
                  <li>Wincross</li>
                  <li>Excel</li>
                  <li>Q Software</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="subHead">Open End Coding</div>
          <div>
            Open ended responses provide very valuable information and insights,
            which usually lacks in close ended responses. Our domain expert at
            GSS perform verbatim coding easily and accurately with compliance to
            client standards.
          </div>
        </div>
      </div>

      <div className="dpCont dpCont3">
        
        <div className="dataGifCont">

        

          <div className="dataGifContText">
            <div className="orangeDots" data-aos="zoom-in">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <h1 data-aos="fade-down">
              PERSONALIZED <br />
              DASHBOARDS
            </h1>
            <div data-aos="fade-up">
              Dashboards that deliver relevant insights, engage users, and
              empower data-driven decision-making.
            </div>

            <div data-aos="fade-down">
              <ul>
                <li>
                  Keeping the layout clean and uncluttered for easy
                  comprehension.
                </li>
                <br />
                <li>
                  We use appropriate visualization types that best communicate
                  the data story.
                </li>
                <br />
                <li>
                  We approach best practices for data encryption, user
                  authentication, and secure data transmission.
                </li>
                <br />
              </ul>
            </div>
          </div>

          <div data-aos="fade-left" className="imgCont">
            <img src={data1} alt="" />
          </div>

        </div>

      </div>

      <div className="dpCont flex advance">

     

       

        <div className="advanceText" data-aos="fade-right">
          <div className="advanceTextDiv">
            ADVANCE <br /> ANALYSIS
          </div>
          <a onClick={showContact} className="rfd">
            REQUEST FOR DEMO
          </a>
        </div>
        <div className="advanceImgDiv" data-aos="fade-left">
          <img src={data3} alt="" />
        </div>

      </div>

      <div className="dpCont charts flex">
      <div className="chartImg" data-aos="fade-right">
          <img src={data2} alt="" />
        </div>
        <div className="chartText" data-aos="fade-left">
          <h3>
            <i>EXPLORE THE WHOLE NEW CHARTS</i>
          </h3>
          <ul>
            <li>Element which influences customer behavior.</li>
            <li>Identifies the area to which you need to pay attention</li>
            <li>
              Guides you to understand which product should be placed in which
              location.
            </li>
            <li>Predicts the sales volume.</li>
          </ul>
          <div>
            We have expertise in the team who can help you with visual
            reports/charts.
          </div>
          <br />

          <div>Tools: Tableau, Power BI, Q, Google charts</div>
          <h1>
            EMPOWER WITH <br />
            VISUAL CHARTS
          </h1>
        </div>
       
      </div>
    </section>
  );
}

export default DataProcessing;
