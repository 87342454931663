import React,{useState} from 'react'
import Logo from "../images/logo.png"
function PopUp() {
    const [cpop,setCpop] = useState(false);
    let popCont = document.querySelector(".popCont");
    function showContact(){
        document.querySelector(".contactPopUp").style.zIndex = "200";
        document.querySelector(".contactPopUp").style.display = "flex";
        setTimeout(()=> document.querySelector(".contactPopUp").style.transform = "scale(0.8)",20)
    document.querySelector(".captchaCont").style.pointerEvents = "revert"

        handlePopUp();
    }

    function handlePopUp(){
        document.querySelector(".popCont").style.transform = "scale(0)";
        document.querySelector(".popCont").style.transform = "translateY(110%)";
    }
    setTimeout(()=>{
        document.querySelector(".popCont").style.display = "block";
    },15000)
  return (
    
    <div className='popCont'>
        <div className="popTop">
            <div className="popLogo"><img src={Logo}/></div>
            <div className='topText'>Are you looking for?</div>
            <i class="fa-sharp fa-solid fa-xmark closeIcon" onClick={()=>handlePopUp()}></i>
        </div> 
        <div className="proMid">
        {/* <div className="proMidHead">Are you looking for following services ?</div> */}
        <div className='proMidBtn' onClick={()=>showContact()}><a >Survey Programming</a></div>
        <div className='proMidBtn' onClick={()=>showContact()}><a >Project Management</a></div>
        <div className='proMidBtn' onClick={()=>showContact()}><a >Data Processing </a></div>
        <div className='proMidBtn' onClick={()=>showContact()}><a > Panel </a></div>
        <div className='proMidContact' style={cpop?{display:"block"}:{display:"none"}}>
        <div>Request for quote here:-</div>
        <div>projects@globalsurveysolutions.com</div>
        <div> +91 9899061991</div>
        </div>
        <div className='gtBtn'><a href="/contactUs">Get in touch</a></div>
       
        </div>
        
    </div>
  )
}

export default PopUp