const ServicesItems = [
    {
        title: 'Survey Programming',
        path: '/surveyprogramming'
    },
    {
        title: 'Data Processing',
        path: '/dataprocessing'
    },
    {
        title: 'Panel Services',
        path: '/panel'
    },
    {
        title: 'Project Management',
        path: '/projectManagement'
    },
   
   
]

export default ServicesItems