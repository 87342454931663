import React from "react";

import Norway from "../images/flags/no.png";
import Sweden from "../images/flags/se.png";
import Denmark from "../images/flags/dk.png";
import Germany from "../images/flags/de.png";
import Estonia from "../images/flags/ee.png";
import Latvia from "../images/flags/lv.png";
import Lithuania from "../images/flags/lt.png";
import Italy from "../images/flags/it.png";
import Finland from "../images/flags/fi.png";
import Poland from "../images/flags/pl.png";
import UnitedKingdom from "../images/flags/gb.png";
import France from "../images/flags/fr.png";
import Austria from "../images/flags/at.png";
import Netherlands from "../images/flags/nl.png";
import India from "../images/flags/in.png"

function OurReach() {
  return (
    <section id="ourReach" className="ourReach">
      <div className="ourReachCont">
      <div className="ourReachImgCont moving-text">
      <div className="ourReachText">Our Reach</div>
      <div className="ourReachImg"> <img src={India}/></div> 
      <div className="ourReachImg"> <img src={Norway}/></div> 
      <div className="ourReachImg"> <img src={Sweden}/></div> 
      <div className="ourReachImg"> <img src={Denmark}/></div> 
      <div className="ourReachImg"> <img src={Germany}/></div> 
      <div className="ourReachImg"> <img src={Estonia}/></div> 
      <div className="ourReachImg"> <img src={Latvia}/></div> 
      <div className="ourReachImg"> <img src={Lithuania}/></div> 
      <div className="ourReachImg"> <img src={Italy}/></div> 
      <div className="ourReachImg"> <img src={Finland}/></div> 
      <div className="ourReachImg"> <img src={Poland}/></div> 
      <div className="ourReachImg"> <img src={UnitedKingdom}/></div> 
      <div className="ourReachImg"> <img src={France}/></div> 
      <div className="ourReachImg"> <img src={Austria}/></div> 
      <div className="ourReachImg"> <img src={Netherlands}/></div> 
    </div>
    </div>

    </section>
  );
}

export default OurReach;
