import React from "react";

function WCU() {
  return (
    <section id="wcu" className="wcu">
      <div className="wcuCont">
      <div className="wcuHead">WHY CHOOSE US?</div>
      <div className="wcuContentDiv">
        <div className="wcuCard" data-aos="fade-right">
          <div className="wcuCardHead">
            CUSTOMIZED <br />
            FEATURES
          </div>
          <div className="wcuCardContent" >
            We provide a full flexibility with customised features to design
            the research. We do provide mock-ups or even a live demonstration to
            meet or understand the client's needs.
          </div>
          <div className="colorDivCont">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div className="wcuCard" data-aos="zoom-in">
          <div className="wcuCardHead">24/7 SUPPORT</div>
          <div className="wcuCardContent">
            Our team is available around the clock, every day of the week,
            including weekends and holidays. Any issues or concerns that arise
            will be addressed promptly, regardless of the time of day. This
            ensures minimal downtime and quick resolutions to keep their
            business operations running smoothly.
          </div>
          <div className="colorDivCont">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div className="wcuCard" data-aos="fade-left">
          <div className="wcuCardHead">
            EXPERTISE
            <br />
            AND <br /> EXPERIENCE
          </div>
          <div className="wcuCardContent">
          Our team brings a wealth of expertise and experience to the table. We have a proven track record of delivering successful projects and achieving client satisfaction. Our knowledge of the industry enables us to understand your unique needs and provide tailored solutions.
          </div>
          <div className="colorDivCont">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        

      </div>
      </div>
    </section>
  );
}

export default WCU;
