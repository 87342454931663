import React from "react";
import Reach from './Reach'

function RFQ() { 
  function showContact(){
    document.querySelector(".contactPopUp").style.zIndex = "200";
    document.querySelector(".contactPopUp").style.display = "flex";
    setTimeout(()=> document.querySelector(".contactPopUp").style.transform = "scale(0.8)",20)
    document.querySelector(".captchaCont").style.pointerEvents = "revert"

}
  return (
    <section className="rfq" id="rfq">
      <div className="rfqCont">
      <div class="textDiv flex">
          <div></div>
          <div>LOOKING TO COLLABORATE....</div>
        </div>
      <div className="rfqCardCont">
        <div className="rfqCard" data-aos="fade-right">
          <div></div>
          <div className="rfqCardHead">SURVEY PROGRAMMING/DATA PROCESSING</div>
          <div className="rfqCardContent">
            Outsourcing programming and data services can significantly reduce
            costs compared to hiring and maintaining an in-house team.
          </div>
          <div className="rfqBtn" onClick={showContact}>
            <a >REQUEST FOR QUOTE</a>
          </div>
        </div>
        <div className="rfqCard" data-aos="fade-in">
          <div></div>
          <div className="rfqCardHead">OFFSITE FULL TIME DP</div>
          <div className="rfqCardContent">
            Cost savings access to specialized skills and expertise scalability
            and flexibility focus on core competencies Increased efficiency and
            productivity.
          </div>
          <div className="rfqBtn" onClick={showContact}>
            <a>REQUEST FOR QUOTE</a>
          </div>
        </div>
        <div className="rfqCard" data-aos="fade-left">
          <div></div>
          <div className="rfqCardHead">PROJECT MANAGEMENT</div>
          <div className="rfqCardContent">
            Outsourcing project management services can be beneficial for
            organizations that lack in-house project management expertise or
            require additional support to ensure project success.
          </div>
          <div className="rfqBtn" onClick={showContact}>
            <a>REQUEST FOR QUOTE</a>
          </div>
        </div>
      </div>
      <Reach/>
      </div>
    </section>
  );
}

export default RFQ;
