import React from "react";
import panelImg from "../images/panelImg.png";
import comingSoon from "../images/comingSoon.png"

function Panel() {
  return (
    <section className="panel" id="panel">
      <div className="panelCont flex">
        <div className="panelLeft" data-aos="fade-in">
          We are collaborated with a market research panel provider,
          organizations that have a ready-made pool of qualified participants,
          leverage the provider's expertise in research methodologies and data
          collection, and obtain valuable insights to inform their business
          decisions and strategies.
          <br />
          <br />
          <br />
          We maintain open communication with the panel provider throughout the
          collaboration. Provide feedback on their services, address any issues
          or concerns, and share your satisfaction or recommendations for
          improvement. This helps build a strong working relationship and
          ensures the success of future collaborations.
        </div>
        <div className="panelRight">
        <div className="panelImgCont">
            <img src={panelImg} alt="" data-aos="fade-left"/>
            <div className="orangeStrip"></div>
        </div>
        <div className="panelHeadDiv">
          <div className="leftBorder">
            <div></div>
            <div></div>
          </div>
        <div className="panelHead" data-aos="fade-in">PANEL SERVICES</div>
        <div className="flex comingSoonDiv">
        <img src={comingSoon} data-aos="fade-right"/>
        </div>
        <div className="collab">KNOW HOW TO COLLABORATE</div>
        <div className="contactText">contactus@globalsurveysolutions.com</div>
        </div>
        </div>
      </div>
    </section>
  );
}

export default Panel;
