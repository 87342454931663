import React, { useEffect } from "react";
import AOS from "aos";
import anuj from "../images/anuj.jpg";
import anjali from "../images/anjali.jpg";
import devyani from "../images/devyani.jpg";
import shailesh from "../images/shailesh.jpg";
import dharmendra from "../images/dharmendra.jpg";
import team from "../images/team.jpeg";

function Team() {
  return (
    <section className="team" id="team">
      <div className="teamCont">
        <div className="teamTop">
        <div class="textDiv flex">
                <div></div>
                <div data-aos="fade-left">OUR TEAM</div>
              </div>
       
        <img src={team} alt="" data-aos="zoom-in"/></div>
        <div className="teamMiddle">

          <div className="teamCard" data-aos="fade-up">
            <img src={shailesh} alt="" />
            <div>Shailesh Patel</div>
            <div>Founder and CEO</div>
          </div>

          <div className="teamCard" data-aos="fade-up">
            <img src={devyani} alt="" />
            <div>Devyani Bhaskar</div>
            <div>Director</div>
          </div>

          <div className="teamCard" data-aos="fade-up">
            <img src={dharmendra} alt="" />
            <div>Dharmendra Kumar</div>
            <div>Head Production Team</div>
            
          </div>

          <div className="teamCard" data-aos="fade-up">
            <img src={anjali} alt="" />
            <div>Anjali Varun</div>
         
            <div>Head Analyst Team</div>
          </div>

          <div className="teamCard" data-aos="fade-up">
            <img src={anuj} alt="" />
            <div>Anuj Ahlawat</div>
            <div>Head Programming Team</div>
          </div>


        </div>
        <div className="teamBottom">
          <div>"The idea behind the GSS, is to fill the gap between research offerings and requirements.Keeping in mind that market research is for discovering what people want, need, or believe" - CEO</div>
          <div>With 16+ year of experience in market research. We have professional who worked with renowned  multinational MR corporations, and have vast industry experience in MR operations, process and team management.</div>
        </div>
      </div>
    </section>
  );
}

export default Team;
