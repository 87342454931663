import React from 'react'
import ContactUs from './ContactUs'

function ContactPopUp() {
    function closeContactPopUp(){
        document.querySelector(".contactPopUp").style.zIndex = "-100";
        document.querySelector(".contactPopUp").style.transform = "scale(0)";
        setTimeout(()=>document.querySelector(".contactPopUp").style.display = "none",500)
        document.querySelector(".captchaCont").style.pointerEvents = "none"
      }
  return (
    <div className='contactPopUp'>
    <i class="fa-sharp fa-solid fa-xmark contactClose" onClick={()=>closeContactPopUp()}></i>
    <ContactUs/>
  </div>
  )
}

export default ContactPopUp