import React, { useState } from 'react'
  function DropDown(props) {
  
      const [click, setClick] = useState(false)
  
      const handleClick = () => setClick(!click)
  
      return (
          <>
              <section className='drop-section'>
                  <ul onClick={handleClick} className={click ? 'drop-menu' : 'dropdown-menu'}>
                      {props.items.map((item, index) => {
                          return (
                              <li key={index}>
                                  <a className='menu-items' href={item.path} onClick={() => setClick(false)}>
                                      {item.title}
                                  </a>
                              </li>
                          )
                      })}
                  </ul>
              </section>
          </>
      )
  }
  
  export default DropDown;