import React from "react";
import spGif from "../images/spGif.gif";
import pc from "../images/pc.png";
import mobileComp from "../images/mobileCompatible.gif";
import sp1 from "../images/SP1.gif";
import sp2 from "../images/SP2.gif";
import sp3 from "../images/SP3.gif";
import sp4 from "../images/SP4.gif";
import sp5 from "../images/SP5.gif";
import sp6 from "../images/SP6.gif";
import sp7 from "../images/SP7.gif";

function SurveyProgramming() {
  function showContact() {
    document.querySelector(".contactPopUp").style.zIndex = "200";
    document.querySelector(".contactPopUp").style.display = "flex";
    setTimeout(
      () =>
        (document.querySelector(".contactPopUp").style.transform =
          "scale(0.8)"),
      20
    );
    document.querySelector(".captchaCont").style.pointerEvents = "revert";
  }
  return (
    <section className="surveyProgramming" id="surveyProgramming">
     

      <section className="spPage1">
        <div className="spCont flex">
          <div className="spLeft">
           
            <div className="spHead">
              <div className="spHead1">
                <div class="textDiv flex">
                  <div></div>
                  <div data-aos="fade-left">SURVEY PROGRAMMING</div>
                </div>
              </div>
            </div>
            <img src={spGif} data-aos="fade-right" alt="" />
          </div>
          <div className="spRight">
            <div data-aos="fade-left">
              "Engage, Empower, and Evolve: Unleash Insights with Interactive
              Surveys"
            </div>
            
            <div className="flex spSubHead" data-aos="fade-right">
              <div className="orangeCircle"></div>
              <img src={pc} alt="" />
              <div>Making layouts and features as per the client need</div>
            </div>
            <div className="spLast" data-aos="fade-in">
              Our team are expertise in online market research and is proficient
              in use of tools such as ConfirmIT, Decipher, SPSS, Dimensions,
              Net-MR and QuestionPro to handle any complexity project
              requirements and deliver them on time. GSS's programmers are well
              versed in using all scripting languages like Java script, CSS,
              Ajax, ASP, Flash Programming, and all possible ways to increase
              the beauty of the survey and make it more interactive.
            </div>
          </div>
        </div>
      </section>

      {/* custom setups page 1 */}

      <section className="customSection">
        <div className="spCustomCont flex">
          <div className="content">
            <div className="textContainer flex">
              <div
                data-aos="fade-right"
                style={{ boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px" }}
              >
                Engage users and enhance their experience on surveys.
              </div>
              <div
                className="tiles"
                style={{ fontWeight: "bolder", background: "var(--rfqBack)" }}
                data-aos="fade-up"
              >
                <span className="rotate ">
                  "A wide range of Interactive Sliders"
                </span>
              </div>
            </div>
            <div className="gifCont" data-aos="fade-right">
              <img src={sp1} alt="" />
            </div>
          </div>
          <div className="content cubeCont">
            <div className="textContainer block ">
              <div className="cube" data-aos="fade-up">
                <img src={sp3} alt="" />
              </div>
              <div
                className="enhance tiles"
                data-aos="fade-down"
                style={{
                  background: "var(--rfqBack)",
                  fontWeight: "bolder",
                  height: "30% !importnat",
                }}
              >
                "Enhancing the brand"
              </div>
            </div>
            <div
              className="gifCont sp3"
              style={{ boxShadow: "none" }}
              data-aos="zoom-in"
            >
              {/* <img src={sp3} alt="" /> */}
              Personalized method of showcasing images in a manner that improves
              or reinforces the brand's identity, values, or overall perception.
            </div>
          </div>
          <div className="content last">
            <div className="gifCont" data-aos="fade-left">
              <img src={sp4} alt="" />
            </div>
            <div className="flex textContainer last">
              <div
                className="tiles"
                style={{ background: "var(--rfqBack)", fontWeight: "bolder" }}
                data-aos="fade-up"
              >
                <span className="rotate"> "Complex algorithms"</span>
              </div>
              <div
                data-aos="fade-down"
                style={{ boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px" }}
              >
                We have the experts to work complex algorithms that have solid
                understanding of the relevant mathematical or computational
                concepts
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* custom setups page 2 */}
      <section className="customSection2">
        <div className="spCustomCont1">
          <div className="upperDiv flex">
            <div data-aos="fade-right">
              <img src={sp6} alt="" />
            </div>
            <div className="listCont" data-aos="fade-left">
              <div>
                <h3>PERSONALIZED SHELF AND MANY MORE...</h3>
              </div>
            </div>
            <div>
              <div className="gifCont" data-aos="fade-right">
                <img src={sp7} alt="" />
              </div>
            </div>
          </div>

          <div className="lowerDiv flex">
            <div className="lowerList">
              <ul>
                <li>Creating a real time experience for the user.</li>
                <li>Customized Shelf Testing</li>
                <li>Group rankings</li>
                <li>Personalized graphics sliders and zoom</li>
                <li>Many more...</li>
              </ul>
            </div>
            <div className="gifCont lastGifCont" data-aos="fade-left">
              <img src={sp2} alt="" />
            </div>
            <div className="knowMore" data-aos="zoom-in">
              Know more...
              <br />
              <br />
              Request for quote
              <br />
              <br />
              <a onClick={showContact}>
                {" "}
                <i class="fa-solid fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* mobile compatible */}
      <section className="mobileCompatible">
        <div className="spCont flex">
          <div className="spLeft">
            <div className="leftBorder">
              <div></div>
              <div></div>
            </div>
            <div className="spHead">
              <div className="spHead1">
                <div class="textDiv flex">
                  <div></div>
                  <div data-aos="fade-left">MOBILE COMPATIBLE</div>
                </div>
              </div>
            </div>
            <img src={mobileComp} data-aos="fade-right" alt="" />
          </div>
          <div className="spRight">
            <div data-aos="fade-left" className="lastQuote">
              "Mobile-Optimized Interactive Surveys: Engage and Explore on Any
              Device"
            </div>
          
            <div className="spLast" data-aos="fade-in">
              <ul>
                <li>Responsive design</li>
                <li>Touch-friendly interface</li>
                <li>Streamlined and concise</li>
                <li>Compatibility across devices and platforms</li>
                <li>Offline capabilities</li>
                <li>Multimedia integration</li>
                <li>Real-time data collection</li>
                <li>Integration with mobile features</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default SurveyProgramming;
