import React,{useState} from 'react'
import Car1 from '../images/car1.png'
import Car2 from '../images/car2.png'
import Car4 from '../images/car4.png'
import Car5 from '../images/car5.png'

function Career() {
  function handleBackClick(){
    if(showPost) setShowPost(false);
  }
  const [showPost,setShowPost] = useState(false);
  return (
   <section id='career' className='career' onClick={()=>handleBackClick()}>

    <div className="carFirstPage">
      <div className='contentCont'>
        <div className='careerCont' style={{
  display: showPost ? 'none' : 'flex'
}} >
      <div className='div1'>A Smart Move</div>
      <div className='div2'>We are rated 5 star company to work in NCR <br />
India; and for good reasons. You'll love it here</div>
<div className='starDiv'><i class="fa-sharp fa-solid fa-star"></i><i class="fa-sharp fa-solid fa-star"></i><i class="fa-sharp fa-solid fa-star"></i><i class="fa-sharp fa-solid fa-star"></i><i class="fa-sharp fa-solid fa-star"></i> </div>
</div>
<div className='posBtn' onClick={()=>setShowPost(!showPost)}>View open positions

<div className='openPositions' style={{
  height: showPost ? '200px' : '0px'
}}>
  <div>Business Development Manager</div>
  <div>Trainee Survey Programmer</div>
  <div>Associate Survey Programmer</div>
  <div>Trainee Data Analyst</div>
  <div>Associate Data Analyst</div>
  <div>Senior Survey Programmer</div>
  <div>Senior Data Analyst</div>
</div>
</div>
<a href='#carSecondPage' className='joinUsBtn'>Join Us</a>

    </div>
    </div>
    <div className="carSecondPage" id='carSecondPage'>
      <div className='textDiv'>
        <div>Your new home away from home</div>
        <div>We'd love to have more talented people on board</div>
        <div>You can send your resume at career@globalsurveysolutions.com</div>
      </div>
      <div className='imgDiv'>
        <img src={Car1} alt="" />
        <img src={Car2} alt="" />
        <img src={Car4} alt="" />
        <img src={Car5} alt="" />
      </div>
    </div>
   </section>
  )
}

export default Career